import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Button from "@mui/material/Button";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../Firebase";
import "./AdminSetting.css";

import { Tooltip } from "@mui/material";
import axios from "axios";
import { t } from "i18next";
import { toast } from "react-toastify";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import NavbarTop from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";

export const CompanyDepartmentSettings = () => {
  const [showdepform, setShowdepform] = useState(false);

  const { clrs } = useSelector((state) => state.createClr);
  //================company settings===========================//
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);

  const fetchCompany = httpsCallable(functions, "fetchCompany");
  const deleteCompany = httpsCallable(functions, "deleteCompany");

  const fetchDepartment = httpsCallable(functions, "fetchDepartment");
  const [department, setDepartment] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [deledep, setDeldep] = useState(true);
  // delete company
  const deletetemplate = (id) => {
    console.log(id);
    let configCompany = {
      method: "delete",
      // params: { tempuid: data.id },
      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        companyId: id,
      }),
    };
    axios(configCompany).then((res) => {
      //console.log(res);
      alert("Company Deleted");
      setdeltemp(!deltemp);
    });
  };
  // get company and department data
  const fetchdata = () => {
    setLoading(true);
    let configcompany = {
      method: "get",
      // params: { tempuid: data.id },
      url: `${IP}/common/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(configcompany)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        if (res.data?.error) {
        } else {
          settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  const fetchDepartmentdata = () => {
    setLoading1(true);
    let configdepartment = {
      method: "get",
      // params: { tempuid: data.id },
      url: `${IP}/common/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(configdepartment)
      .then((res) => {
        //console.log(res);
        if (res.data.length > 0) {
          setDepartment(res.data);
        }
        setLoading1(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading1(false);
      });
  };

  //================make default settings===========================//

  const DefaultCompany = (id) => {
    console.log("default c id", id);
    setLoading(true);
    let configCompany = {
      method: "patch",
      // params: { tempuid: data.id },
      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        companyId: id,
      }),
    };
    axios(configCompany)
      .then((res) => {
        //console.log(res);
        setdeltemp(!deltemp);
        setLoading(false);
        alert("Company Default changed");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const DefaultDepartment = (id) => {
    console.log("default d id", id);
    setLoading1(true);

    let configdepartment = {
      method: "patch",
      // params: { tempuid: data.id },
      url: `${IP}/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        departmentId: id,
      }),
    };
    axios(configdepartment)
      .then((res) => {
        //console.log(res);
        setDeldep(!deledep);
        setLoading1(false);
        alert("Department Default changed");
      })
      .catch((err) => {
        setLoading1(false);
        console.log(err);
      });
  };

  //================create Department===========================//

  const createDepartment = httpsCallable(functions, "createDepartment");
  const [saveloading, setSaveloading] = useState(false);
  const [depName, setDepName] = useState("");

  const summitDepartment = () => {
    // //console.log(
    //   "department",
    //   depName,
    //   depName.split(" ").join("_") + "_" + new Date().getTime().toString()
    // );
    if (depName === "") return alert("Department name is required");
    setSaveloading(true);

    let configdepartment = {
      method: "post",
      // params: { tempuid: data.id },
      url: `${IP}/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        name: depName,
        departmentId:
          depName.split(" ").join("") + "_" + new Date().getTime().toString(),
      }),
    };
    axios(configdepartment)
      .then((res) => {
        //console.log(res);
        alert("Department Created");
        fetchDepartmentdata();
        setSaveloading(false);
        setDeldep(!deledep);
      })
      .catch((err) => {
        // console.log(err.response.data.message);
        if (err.response.data?.message) {
          alert(err.response.data.message);
        }
        setSaveloading(false);
      });
  };

  const deleteDepartment = httpsCallable(functions, "deleteDepartment");
  const deleteDepart = (id) => {
    let configdepartment = {
      method: "delete",
      // params: { tempuid: data.id },
      url: `${IP}/department`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        departmentId: id,
      }),
    };
    axios(configdepartment)
      .then((res) => {
        //console.log(res);
        setDeldep(!deledep);
        alert("Department Deleted");
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    fetchdata();
  }, [deltemp]);
  useEffect(() => {
    fetchDepartmentdata();
  }, [deledep]);

  const navigate = useNavigate();

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Company and Department Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}

              <Button
                variant="contained"
                style={{
                  marginBottom: "20px",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>

              <h1>Company Details</h1>
              <div className="create-new-box">
                <Link to={{ pathname: "/admin/settings/companycreate" }}>
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    startIcon={<AddIcon />}
                  >
                    Create New
                  </Button>
                </Link>
              </div>

              <div
                className="bigger-container"
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <div> Company Name</div>
                <div> Company Id</div>

                <div style={{ display: "flex" }}>
                  <div>Action</div>
                  <div style={{ marginLeft: "100px", marginRight: "10px" }}>
                    Make Default
                  </div>
                </div>
              </div>
              <div className="setting_details_table">
                {showLoading && (
                  <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td">
                      <div className="spinner-container">
                        <div className="loading-spinner"></div>
                      </div>
                    </div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    ></div>
                  </div>
                )}

                {template?.map((ele) => {
                  return (
                    <div className="outer-inner-container">
                      <div className="templatename">{ele.companyName}</div>
                      <div
                        className="templatename"
                        style={{
                          whiteSpace: "initial",
                          width: "200px",
                          lineBreak: "anywhere",
                        }}
                      >
                        {ele.companyId}
                      </div>

                      <div className="button-box">
                        <div className="button-box-inner mx-3 my-1">
                          <Tooltip title="Copy companyId">
                            <ContentCopyIcon
                              sx={{ "&:hover": { cursor: "pointer" } }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `${ele?.companyId}`
                                );
                                toast("companyId copied to clipboard");
                              }}
                            />
                          </Tooltip>
                        </div>
                        {!ele.isDefault && (
                          <div className="button-box-inner mx-3 my-1">
                            <Button
                              variant="contained"
                              component="span"
                              color="warning"
                              onClick={() => {
                                deletetemplate(ele.companyId);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        )}

                        <div className="button-box-inner mx-3 my-1">
                          <Link
                            to={{
                              pathname: "/admin/settings/edit-company",
                              state: { fromDashboard: "it is first" },
                            }}
                            state={ele}
                          >
                            <Button
                              variant="contained"
                              component="span"
                              style={{
                                background: `${
                                  clrs.CourseListBtn
                                    ? clrs.CourseListBtn
                                    : "hwb(224deg 78% 3%)"
                                }`,
                              }}
                            >
                              Edit
                            </Button>
                          </Link>
                        </div>
                        <div className="button-box-inner mx-3 my-1">
                          {/* <Link to={{
                pathname: "/certificatedetails",
            state: { fromDashboard: "it is first" }}}> */}
                          <Link
                            to={{
                              pathname: "/admin/settings/view-company",
                              state: { fromDashboard: "it is first" },
                            }}
                            state={ele}
                          >
                            <Button
                              variant="contained"
                              component="span"
                              style={{
                                background: `${
                                  clrs.CourseListBtn
                                    ? clrs.CourseListBtn
                                    : "hwb(224deg 78% 3%)"
                                }`,
                              }}
                            >
                              View
                            </Button>
                          </Link>
                          {/* </Link>   */}
                        </div>

                        <div
                          className="button-box"
                          style={{ marginLeft: "100px", marginRight: "50px" }}
                        >
                          <input
                            type="checkbox"
                            name={"default"}
                            checked={ele?.isDefault}
                            onChange={(e) => DefaultCompany(ele.companyId)}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* Department Settings */}
              <h1>Department Details</h1>

              <div className="create-new-box">
                <Button
                  variant="contained"
                  component="span"
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  startIcon={<AddIcon />}
                  onClick={() => setShowdepform(!showdepform)}
                >
                  Add Department
                </Button>
              </div>

              {showdepform && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <span style={{ fontSize: "25px" }}>Department Name: </span>
                    <input
                      style={{
                        margin: "20px 5px",
                        borderRadius: "5px",
                        padding: "5px",
                        fontSize: "18px",
                      }}
                      type="text"
                      onChange={(e) => setDepName(e.target.value)}
                    />
                  </div>
                  <div>
                    <Button
                      disabled={saveloading}
                      variant="contained"
                      style={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                      onClick={summitDepartment}
                    >
                      {saveloading ? "Loading" : "Save"}
                    </Button>
                  </div>
                </div>
              )}

              <div
                className="bigger-container"
                style={{
                  borderRadius: "5px 5px 0px 0px",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
              >
                <div> Department Name</div>
                <div> Department Id</div>

                <div style={{ display: "flex" }}>
                  <div>Action</div>
                  <div style={{ marginLeft: "100px", marginRight: "10px" }}>
                    Make Default
                  </div>
                </div>
              </div>

              <div className="setting_details_table">
                {loading1 && (
                  <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td">
                      <div className="spinner-container">
                        <div className="loading-spinner"></div>
                      </div>
                    </div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    ></div>
                  </div>
                )}

                {department?.map((ele) => {
                  return (
                    <div className="outer-inner-container">
                      <div className="templatename">{ele.name}</div>
                      <div className="templatename">{ele.departmentId}</div>

                      <div className="button-box">
                        <div className="button-box-inner mx-3 my-1">
                          <Tooltip title="Copy departmentId">
                            <ContentCopyIcon
                              sx={{ "&:hover": { cursor: "pointer" } }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `${ele?.departmentId}`
                                );
                                toast.success(
                                  "departmentId copied to clipboard"
                                );
                              }}
                            />
                          </Tooltip>
                        </div>
                        {!ele.isDefault ? (
                          <div className="button-box-inner mx-3 my-1">
                            <Button
                              variant="contained"
                              component="span"
                              color="warning"
                              onClick={() => {
                                deleteDepart(ele.departmentId);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        ) : (
                          <div className="button-box-inner mx-3 my-1">
                            <Button
                              variant="contained"
                              component="span"
                              color="warning"
                              disabled={true}
                              onClick={() => {
                                // deleteDepart(ele.departmentId);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        )}

                        <div
                          className="button-box"
                          style={{ marginLeft: "160px", marginRight: "50px" }}
                        >
                          <input
                            type="checkbox"
                            name={"default"}
                            checked={ele?.isDefault}
                            onChange={(e) =>
                              DefaultDepartment(ele.departmentId)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
