import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AddNewUser from "./Admin/AdminComponents/AddNewUser";
import AdminSettings from "./Admin/AdminComponents/AdminSetting/AdminSettings";
import CreateCourse from "./Admin/AdminComponents/Builder/CreateCourse";
import Certificates from "./Admin/AdminComponents/Certificates/Certificates";
import CertificateCreate from "./Admin/AdminComponents/CertificateTemplate/CertificateCreate";
import Certificatedetails from "./Admin/AdminComponents/CertificateTemplate/Certificatedetails";
import Dashboard from "./Admin/AdminComponents/Dashboard";
import ExpiringTraining from "./Admin/AdminComponents/Dashboard/ExpiringTraining";
import NewEmployees from "./Admin/AdminComponents/Dashboard/NewEmployees";
import OverdueEnrollments from "./Admin/AdminComponents/Dashboard/OverdueEnrollments";
import UserFailed from "./Admin/AdminComponents/Dashboard/UserFailed";
import UserPassed from "./Admin/AdminComponents/Dashboard/UserPassed";
import GroupEnrollment from "./Admin/AdminComponents/GroupEnrollment/GroupEnrollment";
import GroupEnrollmentEdits from "./Admin/AdminComponents/GroupEnrollment/GroupEnrollmentEdits";
import GroupEdits from "./Admin/AdminComponents/Groups/GroupEdit";
import Groups from "./Admin/AdminComponents/Groups/Groups";
import IndividualEnrollmentList from "./Admin/AdminComponents/IndividualEnrollment/IndividualEnrollmentList";
import TrainExtAdd from "./Admin/AdminComponents/TrainExtAdd";
import TrainExtrenalTrainEn from "./Admin/AdminComponents/TrainExtrenalTrainEn";
import Trainings from "./Admin/AdminComponents/Trainings";
import TrainInternalOffTrainEn from "./Admin/AdminComponents/TrainInternalOffTrainEn";
import TrainInternalTrainEn from "./Admin/AdminComponents/TrainInternalTrainEn";
import TrainInterOffAdd from "./Admin/AdminComponents/TrainInterOffAdd";
import Users from "./Admin/AdminComponents/Users/Users";
import UserView from "./Admin/AdminComponents/Users/UserView";
import "./App.css";
// import LoginPage from "./User/components/loginpage/LoginPage";
// import Hompage from "./User/components/Homepage/Hompage";
// import UserSettings from "./User/components/Settings/Settings";
// import CourseLibraryMain from "./User/components/CourseLibrary/CourseLibraryMain";
// import TrainingMain from "./User/components/Training/TrainingMain";
// import ProfilePageMain from "./User/components/ProfilePage/ProfilePageMain";
// import YourAssignments from "./User/components/YourAssignments/YourAssignments";
// import UserCertificates from "./User/components/Certificates/Certificates";
// import UserReports from "./User/components/UserReports/UserReports";
// import CourseViewAll from "./User/components/CourseLibrary/CourseViewAll";
// import SingleCourse from "./User/components/CourseLibrary/SingleCourse";
// import QuizMainPage from "./User/components/CourseLibrary/Quiz/QuizMainPage";
// import QuizMainPage from "./User/components/CourseLibrary/SingleCourse/Quiz/QuizItem.js";
import axios from "axios";
import { httpsCallable } from "firebase/functions";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AvailableCertificates from "./Admin/AdminComponents/AdminSetting/AvailableCertificates";
import { CompanyDepartmentSettings } from "./Admin/AdminComponents/AdminSetting/CompanyDepartmentSettings";
import { DataManagement } from "./Admin/AdminComponents/AdminSetting/DataManagement";
import { EmailSettings } from "./Admin/AdminComponents/AdminSetting/EmailSettings";
import { CompanyDepartmentCreate } from "./Admin/AdminComponents/AdminSetting/SettingComponents/CompanyDepartmentCreate";
import { EditCompany } from "./Admin/AdminComponents/AdminSetting/SettingComponents/EditCompany";
import { ViewCompany } from "./Admin/AdminComponents/AdminSetting/SettingComponents/ViewCompany";
import { CreateEmailNotification } from "./Admin/AdminComponents/Emails/CreateEmailNotification";
import EmailSettingPage from "./Admin/AdminComponents/Emails/EmailSettingPage";
import GenerateReportPage from "./Admin/AdminComponents/GenerateReports";
import GroupEnrollmentView from "./Admin/AdminComponents/GroupEnrollment/GroupEnrollmentView";
import AddNewEnrollment from "./Admin/AdminComponents/IndividualEnrollment/AddNewEnrollment";
import { IP } from "./config";
import {
  deleteCookie,
  deleteCookiesInServer,
  getCookie,
  getCookieForRfToken,
  getLocalStorage,
  setCookie,
} from "./Cookies";
import { functions } from "./Firebase";
import PageNotFound from "./PageNotFound";
import AddCertificates from "./User/components/AddCertificates/AddCertificates";
import CertificatesMain from "./User/components/Certificates/CertificatesMain";
import ChangePassword from "./User/components/ChangePassword/ChangePassword";
import CourseLibraryMain from "./User/components/CourseLibrary/CourseLibraryMain";
import SingleCourse from "./User/components/CourseLibrary/SingleCourse";
import SingleCourseContentMain from "./User/components/CourseLibrary/SingleCourse/SingleCourseContentMain";
import ViewAllCourses from "./User/components/CourseLibrary/ViewAllCourses";
import DownloadCertificates from "./User/components/DownloadCertificates/DownloadCertificates";
import ForgotPassword from "./User/components/Forgot-password/ForgotPassword";
import Hompage from "./User/components/Homepage/Hompage";
import MyCourses from "./User/components/Homepage/MyCourse";
import LoadingScreen from "./User/components/loadingScreen";
import LoginPage from "./User/components/loginpage/LoginPage";
import MyAccount from "./User/components/MyAccount/MyAccount";
import MyCertificateViewAll from "./User/components/MyCertificateViewAll/MyCertificateViewAll";
import Practice from "./User/components/Practice";
import ProfilePageMain from "./User/components/ProfilePage/ProfilePageMain";
import SetPassword from "./User/components/setPaassword/SetPassword";
import Settings from "./User/components/Settings/Settings";
import Sidebar from "./User/components/Sidebar/Sidebar";
import ExternalTrainingMain from "./User/components/Training/ExternalTrainingMain";
import ExternalViewMaterials from "./User/components/Training/ExternalViewMaterials";
import InternalOfflineTrainingMain from "./User/components/Training/InternalOfflineTrainingMain";
import InternalTrainingMain from "./User/components/Training/InternalTrainingMain";
import OfflineViewMaterials from "./User/components/Training/OfflineViewMaterials";
import PreviousTrainingMain from "./User/components/Training/PreviousTrainingMain";
import TrainingMain from "./User/components/Training/TrainingMain";
import UpadtePassword from "./User/components/updatePassword/updatePassword";
import UserReports from "./User/components/UserReports/UserReports";
import { createClrs } from "./User/Redux/Actions";
import { newGreen } from "./User/Redux/Colors";
// import { toast, ToastContainer } from "react-toastify";

const App = () => {
  const dispatch = useDispatch();

  const saveToken = httpsCallable(functions, "saveToken");

  const messaging = getMessaging();
  const [messagingToken, setMessagingToken] = useState(null);

  const [loading, setLoading] = useState(false);
  // //console.log('Requesting permission...');
  try {
    Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        }
      })
      .then(() => {
        getToken(messaging, {
          vapidKey:
            "BHkGI-ETIL54krulwxgB3oDH8t5g8dq8xh66vr7RLYc4cQNKrpK-wyBdzNNyoa_wT5AzYRjlap9WhvowNLrEX2E",
        })
          .then(async (currentToken) => {
            if (currentToken) {
              // use onAuthStateChange

              console.log(currentToken);
              // save if user is logged in
              let configLesson = {
                method: "post",
                // params: { userArr:userArr },
                url: `${IP}/mail/saveToken`,

                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getCookie("bearer")}`,
                },
                data: JSON.stringify({
                  token: currentToken,
                }),
              };
              axios(configLesson);
              // await saveToken({ token: currentToken });

              setMessagingToken(currentToken);
              // console.log(currentToken);
            } else {
              // //console.log(
              //   "No registration token available. Request permission to generate one."
              // );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
  } catch (e) {}

  if (localStorage.getItem("ROLE")) {
    console.log(localStorage.getItem("ROLE"));
  } else {
    console.log(`href`, window.location.href);
    console.log(`origin`, window.location.origin);
    let baselink = window.location.href.split("?")[0];
    // console.log(`baselink`, baselink);

    if (baselink == `${window.location.origin}/set-password`) {
    } else {
      if (`${window.location.origin}/` != window.location.href) {
        alert("please login again");

        window.open(`${window.location.origin}`, "_self");
      } else {
      }
    }

    // alert("elee");
  }
  useEffect(() => {
    dispatch(createClrs(newGreen));

    if (getLocalStorage("bearer")) {
      let configLesson = {
        method: "post",
        // params: { userArr:userArr },
        url: `${IP}/verifyToken`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          token: getCookie("bearer"),
          rToken: localStorage.getItem("rfToken"),
        }),
      };
      setLoading(true);
      axios(configLesson)
        .then((res) => {
          setLoading(false);
          // window.open(`${window.location.origin}/`, "_self");

          if (res.data?.token) {
            setCookie("bearer", res.data.token);
            // alert(`token,${res.data.token}`);

            // alert("this is the al");

            window.open(`${window.location.href}`, "_self");
          }
        })
        .catch((err) => {
          // alert("erro");
          deleteCookie();
          deleteCookiesInServer();
          setLoading(false);

          window.open(`${window.location.origin}`, "_self");
        });
    }
    // if (localStorage.getItem("role")) {
    //   let role = localStorage.getItem("role");
    //   if (role == "user") {
    //     dispatch(createClrs(newGreen));
    //   }
    // } else {
    //   console.log(`href`, window.location.href);
    //   console.log(`origin`, window.location.origin);

    //   if (`${window.location.origin}/` != window.location.href) {
    //     alert("please login again");

    //     window.open(`${window.location.origin}`, "_self");
    //   } else {
    //   }
    //   // alert("elee");
    // }
    // console.log(newGreen);

    // localStorage.setItem("clrs", JSON.stringify(newGreen));
  }, []);

  useEffect(() => {
    let rf = getCookieForRfToken("rfToken");
    // console.log(rf);
    if (!rf) {
      let rrf = localStorage.getItem("rfToken");
      let tf = typeof rrf;
      if (tf != String && tf != "") {
        // console.log("rf", rf);
        // console.log("rrf", rrf);

        setCookie("rfToken", rrf);
      }
    }
    axios.defaults.withCredentials = true;

    const handleTabClose = (event) => {
      event.preventDefault();

      // return (event.returnValue = "Are you sure you want to exit?");
    };

    window.addEventListener("beforeunload", handleTabClose);

    const pageAccessedByReload =
      (window.performance.navigation &&
        window.performance.navigation.type === 1) ||
      window.performance
        .getEntriesByType("navigation")
        .map((nav) => nav.type)
        .includes("reload");

    //alert(pageAccessedByReload);
    // return () => {

    //   window.removeEventListener("beforeunload", handleTabClose);
    if (!pageAccessedByReload) {
      if (!getCookie("keepMeLogin")) {
        deleteCookie("UID");
        deleteCookie("keepMeLogin");
      }
    }

    // };
  }, []);

  onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);

    // toast.success(payload.notification.body)
    // Post params - fcm, message
    // toast(payload.notification.body);
    NotificationManager.success(
      payload.notification.body,
      payload.notification.title
    ); //success, warning, info, danger

    if (
      payload.notification.title == "Account deleted" ||
      payload.notification.title == "You are archived from the portal"
    ) {
      deleteCookie();

      window.open(`${window.location.origin}`, "_self");
    }
    // alert(payload.notification.body);
  });
  const showNotification = () => {
    NotificationManager.success("Success message", "Title here");
  };

  return (
    <div className="App">
      {/* notification  */}
      {/* <button onClick={showNotification}>show Notification</button> */}
      <NotificationContainer style={{ zIndex: 100 }} />
      <ToastContainer
        position="top-right"
        autoClose={1500}
        // hideProgressBar={false}
        closeOnClick={true}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        draggable
      />

      <LoadingScreen open={loading} />
      <Router>
        <Routes>
          <Route path="/practice" element={<Practice />} />
          {/* User Routes */}
          {/* <Route path="/" element={<LoginPage />} />
          <Route path="/user" element={<Hompage />} />
          <Route path="/user/settings" element={<UserSettings />} />
          <Route path="/user/courselibrary" element={<CourseLibraryMain />} />
          <Route path="/user/training" element={<TrainingMain />} />
          <Route path="/user/profile" element={<ProfilePageMain />} />
          <Route path="/user/yourassignment" element={<YourAssignments />} />
          <Route path="/user/certificates" element={<UserCertificates />} />
          <Route path="/user/userreports" element={<UserReports />} />
          <Route
          path="/user/courselibrary/viewall"
          element={<CourseViewAll />}
          />
          <Route
          path="/user/courselibrary/singlecourse/:courseId"
          element={<SingleCourse />}
          />
          <Route path="/user/courselibrary/quiz" element={<QuizMainPage />} />

          <Route path="/resetPassword" element={<ResetPassword />}></Route> */}
          {/* <Route path="/setPassword" element={<SetPassword />} /> */}
          <Route
            path="/"
            element={<LoginPage messagingToken={messagingToken} />}
          />
          <Route
            path="/update-password"
            element={<UpadtePassword messagingToken={messagingToken} />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="user" element={<Sidebar />}>
            <Route path="home" element={<Hompage />} />
            <Route path="home/add-certificates" element={<AddCertificates />} />
            <Route />
            <Route
              path="home/download-certificates"
              element={<DownloadCertificates />}
            />
            <Route />
            <Route path="home/my-courses" element={<MyCourses />} />
            <Route />
            <Route
              path="home/my-certificate-view-all"
              element={<MyCertificateViewAll />}
            />
            <Route
              path="course-library/single-course-overview"
              element={<SingleCourse />}
            />
            <Route
              path="course-library/single-course-overview/single-course-content/:courseId"
              element={<SingleCourseContentMain />}
            />
            <Route path="course-library" element={<CourseLibraryMain />} />
            <Route path="course-library/viewall" element={<ViewAllCourses />} />
            <Route path="training" element={<TrainingMain />} />
            <Route
              path="training/internal-training"
              element={<InternalTrainingMain />}
            />
            <Route
              path="training/external-training"
              element={<ExternalTrainingMain />}
            />
            <Route
              path="training/internal-offline-training"
              element={<InternalOfflineTrainingMain />}
            />
            <Route
              path="/user/training/external-training/view-materials"
              element={<ExternalViewMaterials />}
            />
            <Route
              path="/user/training/internal-offline-training/view-materials"
              element={<OfflineViewMaterials />}
            />
            {/* /user/training/external-training/view-materials */}
            <Route
              path="training/previous-training"
              element={<PreviousTrainingMain />}
            />
            <Route path="profile" element={<ProfilePageMain />} />
            <Route path="certificates" element={<CertificatesMain />} />
            <Route path="user-reports" element={<UserReports />} />
            <Route path="settings" element={<Settings />} />
            <Route
              path="profile/change-password"
              element={<ChangePassword />}
            />
            <Route path="profile/my-account" element={<MyAccount />} />
          </Route>
          {/* <Route path="/instructor" element={<InstructorDashboard />}></Route> */}
          {/* <Route path="/admin/one" element={<ONe />}></Route> */}
          {/* Admin Routes */}
          <Route path="/admin" element={<Dashboard />}></Route>
          <Route path="/admin/users" element={<Users />}></Route> {/**a */}
          <Route path="/admin/addNewUser" element={<AddNewUser />}></Route>{" "}
          {/**a */}
          <Route
            path="/admin/settings"
            element={<AdminSettings />}
          ></Route>{" "}
          {/**a */}
          <Route
            path="/admin/settings/available-certificates"
            element={<AvailableCertificates />}
          ></Route>{" "}
          {/**a */}
          <Route path="/admin/courseBuilder" element={<CreateCourse />}></Route>
          <Route path="/admin/groups" element={<Groups />}></Route>
          {/**ai */}
          <Route path="/admin/groupEdits/" element={<GroupEdits />}></Route>
          {/**ai */}
          {/* <Route path="/admin/question" element={<QuestionForm />}></Route> */}
          {/* muda muda muda... */}
          {/* <Route path="/admin/externalTraining" element={<ExternalTraining />}></Route> */}
          {/**ai */}
          <Route
            path="/admin/settings/certificate"
            element={<Certificatedetails />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/certificatecreate"
            element={<CertificateCreate />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/email"
            element={<EmailSettings />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/emailtemplate"
            element={<EmailSettingPage />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/email-create"
            element={<CreateEmailNotification />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/company-department"
            element={<CompanyDepartmentSettings />}
          ></Route>
          <Route
            path="/admin/settings/data-management"
            element={<DataManagement />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/companycreate"
            element={<CompanyDepartmentCreate />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/edit-company"
            element={<EditCompany />}
          ></Route>
          {/**a */}
          <Route
            path="/admin/settings/view-company"
            element={<ViewCompany />}
          ></Route>
          {/**a */}
          {/* <Route
            path="/admin/externalTrainingEdits/:id"
            element={<ExternalTrainingEdits />}
          ></Route> */}
          {/**ai */}
          {/* <Route
            path="/admin/internalOfflineTraining"
            element={<InternalOfflineTraining />}
          ></Route> */}
          {/**ai */}
          {/* <Route
            path="/admin/internalOfflineTrainingEdits/:id"
            element={<InternalOfflineTrainingEdits />}
          ></Route> */}
          {/**ai */}
          <Route
            path="/admin/groupEnrollment"
            element={<GroupEnrollment />}
          ></Route>
          {/**ai */}
          <Route
            path="/admin/groupEnrollment/view"
            element={<GroupEnrollmentView />}
          ></Route>
          <Route
            path="/admin/IndividualEnrollment"
            element={<IndividualEnrollmentList />}
          ></Route>
          {/**ai */}
          <Route
            path="/admin/IndividualEnrollment/addNewIndividualEnrollment"
            element={<AddNewEnrollment />}
          ></Route>
          {/**ai */}
          {/* IndividualEnrollmentList */}
          <Route
            path="/admin/groupEnrollmentEdits/"
            element={<GroupEnrollmentEdits />}
          ></Route>
          {/**ai */}
          <Route path="/admin/certificate" element={<Certificates />}></Route>
          {/**a */}
          <Route
            path="/admin/overdueenrollments"
            element={<OverdueEnrollments />}
          />
          {/*ai */}
          <Route path="/admin/userpassed" element={<UserPassed />} />
          {/*ai */}
          <Route path="/admin/userfailed" element={<UserFailed />} />
          {/*ai */}
          <Route path="/admin/newemployees" element={<NewEmployees />} />
          {/*a */}
          <Route
            path="/admin/expiringtraining"
            element={<ExpiringTraining />}
          />
          {/*ai */}
          <Route
            path="/admin/course-reports"
            element={<GenerateReportPage />}
          />
          {/*a */}
          {/* <Route
            path="/admin/course-reports/GenerateReportPage"
            element={<GenerateReportPage />}
          /> */}
          {/*a */}
          <Route path="/admin/userview" element={<UserView />} />
          {/*a */}
          <Route path="/admin/training" element={<Trainings />} />
          <Route
            path="/admin/training/internal"
            element={<TrainInternalTrainEn />}
          />
          <Route
            path="/admin/training/extrenal"
            element={<TrainExtrenalTrainEn />}
          />
          <Route
            path="/admin/training/internalOff"
            element={<TrainInternalOffTrainEn />}
          />
          <Route
            path="/admin/training/extrenal/add"
            element={<TrainExtAdd />}
          />
          <Route
            path="/admin/training/internalOff/add"
            element={<TrainInterOffAdd />}
          />
          <Route path="*" element={<PageNotFound />} />
          {/* <Route path="/admin/previousTraining" element={<PreviousTraining />}></Route> */}
          {/* <Route path="admin/previousTrainingEdits/:id" element={<PreviousTrainingEdits />}></Route> */}
          {/* <Route path="/admin/certificateRenewal/:id" element={<CertificateRenewal />}></Route> */}
          {/* <Route path="/admin/notifications/:id" element={<Notifications />}></Route> */}
          {/* <Route path="/admin/courselist" element={<CourseList />} /> */}
          {/* <Route path="/admin/training/internal/add" element={<TrainInterAdd />} /> */}
          {/* <Route path="/admin/trainingenrollments" element={<TrainingEnrollments />} /> */}
          {/* <Route path="/admin/internaltraingenrolments" element={<InternalTraining />} /> */}
          {/* <Route path="/admin/coursecreation" element={<CourseCreation />} /> */}
        </Routes>
      </Router>
    </div>
  );
};

export default App;
