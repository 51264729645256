import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./AdminSetting.css";

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { t } from "i18next";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import NavbarTop from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";

export const DataManagement = () => {
  const { clrs } = useSelector((state) => state.createClr);
  const [managementStatus, setManagementStatus] = useState({});
  const [file, setFile] = useState(null);
  const [showLoading, setLoading] = useState(true);

  const fetchdata = () => {
    setLoading(true);
    let config = {
      method: "get",
      // params: { tempuid: data.id },
      url: `${IP}/dataManagement/status`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(config)
      .then((res) => {
        setLoading(false);
        console.log(res);
        setManagementStatus(res.data?.status);
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };
  const createBackup = () => {
    setLoading(true);
    let config = {
      method: "get",
      // params: { tempuid: data.id },
      url: `${IP}/dataManagement/backup`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(config)
      .then(async (res) => {
        setLoading(false);
        console.log(res);
        await handleDownloadFile(res.data.downloadUrl);
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  const onSubmit = async (handleClose) => {
    try {
      if (!file) {
      }
      setLoading(true);
      window.onbeforeunload = function () {
        return true;
      };
      const formData = new FormData();
      formData.append("zipFile", file);

      let config = {
        method: "post",
        url: `${IP}/dataManagement/restore`,
        headers: {
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: formData,
      };
      axios(config).then(async (res) => {
        setLoading(false);
        setTimeout(() => {
          fetchdata();
        }, 500);
      });
      if (handleClose) {
        handleClose?.();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setFile(null);
      window.onbeforeunload = null;
    }
  };

  const handleDownloadFile = async (url) => {
    try {
      setLoading(true);
      let tDate = new Date()
        .toLocaleDateString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .replaceAll("/", "-");
      const downloadFileName = `firestore-data-export-${tDate}.zip`;
      await downloadFile(url, downloadFileName);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchdata();
    const interval = setInterval(() => {
      fetchdata();
    }, 15 * 1000);
    return () => clearInterval(interval);
  }, []);

  const navigate = useNavigate();

  const backupStatus = managementStatus?.backupStatus ?? {};
  const restoreStatus = managementStatus?.restoreStatus ?? {};

  const backupButtonDisabled = showLoading || backupStatus.backupInProgress;
  const restoreButtonDisabled = showLoading || restoreStatus?.restoreInProgress;
  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Data Management")} />
        {/* <Navbar /> */}
        {/* {JSON.stringify(managementStatus)} */}
        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div
            className="admin-dashboard-main srollbar_div"
            style={{ overflow: "auto" }}
          >
            <div className="outer-bigger-container">
              {/* Company settings */}

              <Button
                variant="contained"
                style={{
                  marginBottom: "20px",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>

              <h1>Backup And Restore</h1>
              <div className="">
                <Button
                  variant="contained"
                  component="span"
                  onClick={createBackup}
                  disabled={backupButtonDisabled || restoreButtonDisabled}
                  sx={{
                    my: 2,
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  startIcon={<AddIcon />}
                >
                  Create New Backup
                </Button>
              </div>

              {backupStatus?.backupInProgress && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="bold"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    Backup is in progress
                  </Typography>
                  <br />
                  <CircularProgress />
                </Box>
              )}
              {backupStatus?.backupComplete && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: { xs: "flex-start", md: "center" },
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="semi-bold"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    Last Backup Date -{" "}
                    {new Date(backupStatus.backupCompletedAt)?.toString()}
                  </Typography>
                  {backupStatus?.backupUrl && (
                    <Button
                      variant="contained"
                      component="span"
                      sx={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                      onClick={async () => {
                        await handleDownloadFile(backupStatus?.backupUrl);
                      }}
                    >
                      Download Backup
                    </Button>
                  )}
                  <br />
                </Box>
              )}
              <hr style={{ marginBlock: "16px" }} />
              {restoreStatus?.restoreInProgress && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    Restore started at -{" "}
                    {new Date(restoreStatus?.restoreStartedAt).toString()}
                  </Typography>
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      mb: 1,
                    }}
                  >
                    Restore in progress
                  </Typography>
                  {restoreStatus?.restoreStages?.map((stage, index) => (
                    <>
                      <Typography
                        variant="semibold"
                        sx={{
                          color: (theme) => theme.palette.text.main,
                          display: "flex",
                          alignItems: { xs: "flex-start", md: "center" },
                          gap: 1,
                        }}
                      >
                        {restoreStatus?.currentRestoreStage > index ? (
                          <CheckIcon />
                        ) : (
                          <CircularProgress size={"16px"} />
                        )}{" "}
                        {stage}
                      </Typography>
                    </>
                  ))}
                  <br />
                </Box>
              )}
              {restoreStatus?.restoreCompleted && (
                <Box sx={{ my: 2 }}>
                  <Typography
                    variant="semi-bold"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    Last Restore Completed At -{" "}
                    {new Date(restoreStatus.restoreCompletedAt)?.toString()}
                  </Typography>
                </Box>
              )}

              {!restoreButtonDisabled && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexDirection: "column",
                      gap: 1,
                      width: "400px",
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="medium"
                      sx={{
                        color: (theme) => theme.palette.text.main,
                      }}
                    >
                      Choose Zip file to restore
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: { xs: "flex-start", md: "center" },
                        gap: "10px",
                      }}
                    >
                      <input
                        type="file"
                        disabled={restoreButtonDisabled}
                        accept="application/zip"
                        onChange={(e) => {
                          let file = e.target.files[0];
                          if (!file.name.endsWith(".zip")) {
                            e.target.value = null;
                            setFile(null);
                            return;
                          }
                          setFile(file);
                        }}
                      />
                    </Box>
                  </Box>
                  <ConformationDialog
                    onSubmit={onSubmit}
                    loading={showLoading}
                    restoreButtonDisabled={
                      backupButtonDisabled ||
                      restoreButtonDisabled ||
                      showLoading
                    }
                  />
                </>
              )}

              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}
              {/* <div
                className="bigger-container"
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <div> Company Name</div>
                <div> Company Id</div>

                <div style={{ display: "flex" }}>
                  <div>Action</div>
                </div>
              </div>
              <div className="setting_details_table">
                {showLoading && (
                  <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td">
                      <div className="spinner-container">
                        <div className="loading-spinner"></div>
                      </div>
                    </div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    ></div>
                  </div>
                )}

                {template?.map((ele) => {
                  return (
                    <div className="outer-inner-container">
                      <div className="templatename">{ele.companyName}</div>
                      <div
                        className="templatename"
                        style={{
                          whiteSpace: "initial",
                          width: "200px",
                          lineBreak: "anywhere",
                        }}
                      >
                        {ele.companyId}
                      </div>

                      <div className="button-box">
                        <div className="button-box-inner mx-3 my-1">
                          <Tooltip title="Copy companyId">
                            <ContentCopyIcon
                              sx={{ "&:hover": { cursor: "pointer" } }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `${ele?.companyId}`
                                );
                              }}
                            />
                          </Tooltip>
                        </div>
                        {!ele.isDefault && (
                          <div className="button-box-inner mx-3 my-1">
                            <Button
                              variant="contained"
                              component="span"
                              color="warning"
                              onClick={() => {
                                deletetemplate(ele.companyId);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        )}

                        <div className="button-box-inner mx-3 my-1">
                          <Link
                            to={{
                              pathname: "/admin/settings/edit-company",
                              state: { fromDashboard: "it is first" },
                            }}
                            state={ele}
                          >
                            <Button
                              variant="contained"
                              component="span"
                              style={{
                                background: `${
                                  clrs.CourseListBtn
                                    ? clrs.CourseListBtn
                                    : "hwb(224deg 78% 3%)"
                                }`,
                              }}
                            >
                              Edit
                            </Button>
                          </Link>
                        </div>
                        <div className="button-box-inner mx-3 my-1">
                          {/* <Link to={{
                pathname: "/certificatedetails",
            state: { fromDashboard: "it is first" }}}> 
                          <Link
                            to={{
                              pathname: "/admin/settings/view-company",
                              state: { fromDashboard: "it is first" },
                            }}
                            state={ele}
                          >
                            <Button
                              variant="contained"
                              component="span"
                              style={{
                                background: `${
                                  clrs.CourseListBtn
                                    ? clrs.CourseListBtn
                                    : "hwb(224deg 78% 3%)"
                                }`,
                              }}
                            >
                              View
                            </Button>
                          </Link>
                        </div>

                        <div
                          className="button-box"
                          style={{ marginLeft: "100px", marginRight: "50px" }}
                        >
                          <input
                            type="checkbox"
                            name={"default"}
                            checked={ele?.isDefault}
                            onChange={(e) => DefaultCompany(ele.companyId)}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export function downloadFile(url, customFileName = "download") {
  // Fetch the URL
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      // Create an <a> element and set its attributes
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = customFileName; // Set the custom file name

      // Trigger a click event to initiate the download
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      // Cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(a.href);
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
}

function ConformationDialog({ onSubmit, loading, restoreButtonDisabled }) {
  const { clrs } = useSelector((state) => state.createClr);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        disabled={restoreButtonDisabled}
        variant="contained"
        component="span"
        sx={{
          background: `${
            clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
          }`,
        }}
        onClick={handleOpen}
      >
        Restore
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "400px", pb: "20px" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent sx={{ textAlign: "center" }}>
            <DialogContentText id="alert-dialog-description">
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Are you sure you want to restore?
              </Typography>
              <br />
              <Typography
                variant="regular"
                sx={{
                  color: "red",
                }}
              >
                Note: This action is irreversible
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              disabled={restoreButtonDisabled}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={restoreButtonDisabled}
              onClick={() => onSubmit(handleClose)}
              variant="contained"
              component="span"
              sx={{
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
              }}
            >
              Start Restore
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
